<template>
  <div class="baseInto">
    <!-- 背景图片裁剪弹窗 -->
    <LiefengModal @input="cropperCancel" width="600px" height="500px" :value="cropperStatus">
      <template v-slot:contentarea>
           <div class="cropper-content" v-if="cropperStatus">
        <div class="cropper" style="text-align:center">
        <vueCropper
            ref="cropper"
            :img="option.img"
            :outputSize="option.size"
            :outputType="option.outputType"
            :info="true"
            :full="option.full"
            :canMove="option.canMove"
            :canMoveBox="option.canMoveBox"
            :original="option.original"
            :autoCrop="option.autoCrop"
            :fixed="option.fixed"
            :fixedNumber="option.fixedNumber"
            :centerBox="option.centerBox"
            :infoTrue="option.infoTrue"
            :fixedBox="option.fixedBox"
          ></vueCropper>
        </div>
      </div>
      </template>
      <template v-slot:toolsbar>
        <Button type="info" @click="cropperCancel(false)" style="margin-right: 10px">取消</Button>
        <Button type="primary" @click="cropperFinish">裁剪</Button>
      </template>
    </LiefengModal>

    <!-- 右边表单区块 -->
    <Form
      ref="formValidate"
      :model="formValidate"
      :rules="ruleValidate"
      :label-width="100"
      :label-colon="true"
    >
      <FormItem label="信息名称" prop="title">
        <Input
        :maxlength="50"
          v-model.trim="formValidate.title"
          placeholder="请输入信息名称，限50字"
        />
      </FormItem>
      <FormItem label="信息来源" prop="source">
        <Input
        :maxlength="30"
          v-model.trim="formValidate.source"
          placeholder="请输入信息名称，限30字"
        />
      </FormItem>
      <!-- prop="subtype"  这是信息类型校验，暂无数据 -->
      <FormItem label="信息类型" class="form-item" prop="subtype">
        <Select v-model="formValidate.subtype" filterable>
          <Option
            v-for="(item,index) in subtypeList"
            :value="item.value"
            :key="index"
            >{{ item.label }}</Option
          >
        </Select>
      </FormItem>
      <!-- <FormItem label="联系电话" class="form-item" prop="appointmentPhone"> -->
      <FormItem label="联系电话" class="form-item">
        <Input
        :maxlength="20"
          v-model.trim="formValidate.appointmentPhone"
          placeholder="请输入联系电话"

        />
      </FormItem>
      <FormItem label="公布时间" prop="startTime" class="form-item">
        <FormItem prop="startTime" class="form-item" style="width: 48%">
          <DatePicker
            type="date"
            placeholder="请设置日期"
            v-model="formValidate.startTime"
          ></DatePicker>
        </FormItem>
        <span class="time-interval">-</span>
        <FormItem prop="subStartTime" class="form-item" style="width: 48%">
          <TimePicker
            type="time"
            placeholder="请设置时间"
            v-model="formValidate.subStartTime"
          ></TimePicker>
        </FormItem>
      </FormItem>
      <FormItem label="截至时间" prop="endTime" class="form-item">
        <FormItem prop="endTime" class="form-item" style="width: 48%">
          <DatePicker
            type="date"
            placeholder="请设置日期"
            v-model="formValidate.endTime"
          ></DatePicker>
        </FormItem>
        <span class="time-interval">-</span>
        <FormItem prop="subEndTime" class="form-item" style="width: 48%">
          <TimePicker
            type="time"
            placeholder="请设置时间"
            v-model="formValidate.subEndTime"
          ></TimePicker>
        </FormItem>
      </FormItem>
      <FormItem label="发布范围" class="form-item" style="width: 100%" prop="cityCode">
        <Select
          v-model="formValidate.cityCode"
          filterable
          style="width: 25%"
          placeholder="请选择城市"
          @on-change="changeCity"

        >
          <Option
            v-for="(item, index) in cityList"
            :value="item.code"
            :key="index"
            prop="cityCode"
            >{{ item.name }}</Option
          >
        </Select>
        <Select
          v-model="formValidate.areaCode"
          filterable
          style="width: 25%"
          prop="areaCode"
          placeholder="请选择区县"
          @on-change="changeArea"
        >
          <Option
            v-for="(item, index) in areaList"
            :value="item.code"
            :key="index"
            >{{ item.name }}</Option
          >
        </Select>
        <Select
          v-model="formValidate.streetCode"
          filterable
          style="width: 25%"
          prop="streetCode"
          placeholder="请选择街道/乡镇"
          @on-change="changeStree"
        >
          <Option
            v-for="(item, index) in streeList"
            :value="item.code"
            :key="index"
            >{{ item.name }}</Option
          >
        </Select>
        <Select
          v-model="formValidate.projectCodeList"
          filterable
          style="width: 25%"
          prop="projectCodeList"
          placeholder="请选择社区/村"
        >
          <Option
            v-for="(item, index) in projectList"
            :value="item.code"
            :key="index"
            >{{ item.name }}</Option
          >
        </Select>
      </FormItem>
      <FormItem label="发布终端" class="form-item" style="width: 100%" prop="terminal">
          <CheckboxGroup v-model="formValidate.terminal" @on-change="allSelect">
            <Checkbox label="0">全部</Checkbox>
            <Checkbox label="1">TV</Checkbox>
            <Checkbox label="3">APP</Checkbox>
            <Checkbox label="4">小程序</Checkbox>
          </CheckboxGroup>
        </FormItem>
      <FormItem label="封面图片" class="form-item" style="width: 100%">
         <img ref="image" :src="formValidate.photo" alt=""  width="200" style="display:block;margin-bottom:10px">
         <Progress v-if="file.showProgress" :percent="file.percentage" :stroke-width="5"></Progress>
        <Upload
            ref="imgUpload"
            :before-upload="beforeImage"
            :show-upload-list="false"
            :action="actionImage"
            :data="uploadImageData"
            :on-success="(value, file) => imageSuccess(file)"
            :format="['jpg', 'jpeg','gif', 'png']"
            style="display:inline-block"
            :on-format-error="imageError"
            :on-progress="imageProgress"
        >
          <Button type="info">上传图片</Button>
        </Upload>
        <Button v-if="formValidate.photo" type='success' @click="cropperFn" style="margin-left:10px">裁剪图片</Button>
      </FormItem>
      <!-- <iframe name="ueditor" id="ueditor" src="/ueditor/index.html?titlename=详情描述"
              style="width:100%;height:500px;border:0px;" @load="loadUeditor"></iframe> -->
              <iframe
                name="ueditor"
                id="ueditor"
                src="/mobileeditor/#/index?titlename=详情描述"
                style="width: 100%; height: 700px; border: 0px"
                @load="loadUeditor"
              ></iframe>
    </Form>

    <!--   预览富文本二维码 -->
    <Mymodel
        :fullscreen="false"
        :value="toggle1"
        :toggle="true"
        :textObj="textObj"
        title="文本预览"
        :toggle-fn2="toggleFn2"
    >
    </Mymodel>
  </div>
</template>

<script>
import LiefengModal from '@/components/LiefengModal'
import {VueCropper} from 'vue-cropper'
import Mymodel from "@/views/activity/Mymodel";

export default {
  props: ["formValidate", "ruleValidate",'toggle1','textObj','toggleFn2'],
  data() {
    return {
      originalFile: '',  //上传图片内容
      // 裁剪组件的基础配置option
      option: {
        img: '', // 裁剪图片的地址
        info: true, // 裁剪框的大小信息
        outputSize: 0.8, // 裁剪生成图片的质量
        outputType: 'jpg', // 裁剪生成图片的格式
        canScale: true, // 图片是否允许滚轮缩放
        autoCrop: true, // 是否默认生成截图框
        autoCropWidth: 600, // 默认生成截图框宽度
        autoCropHeight: 500, // 默认生成截图框高度
        fixedBox: false, // 固定截图框大小 不允许改变
        fixed: true, // 是否开启截图框宽高固定比例
        fixedNumber: [100, 69], // 截图框的宽高比例
        full: true, // 是否输出原图比例的截图
        canMoveBox: true, // 截图框能否拖动
        original: false, // 上传图片按照原始比例渲染
        centerBox: true, // 截图框是否被限制在图片里面
        infoTrue: false // true 为展示真实输出图片宽高 false 展示看到的截图框宽高
      },
      cropperStatus: false,
      //上传进度
      file: {},
       // 图片上传参数
      uploadImageData: {},
       //图片名称
      imageName: "",
       //图片路径
      imagePath: "",
       //oss上传地址
      actionImage: "",

      subtypeList: [], //信息类型
      cityList: [],
      areaList: [],
      streeList: [],
      projectList: [],
      parentCode: 44,
      cityCode: "",
      areaCode: "",
      streetCode: "",

      // 发布终端临时变量
      terminalList: [],
    };
  },
  created() {
    sessionStorage.setItem('NODEENV',process.env.NODE_ENV)
    //获取城市
    this.$get("/voteapi/api/pc/cascader/getGovAreaByParentCode", {
      parentCode: 44,
      orgCode: parent.vue.loginInfo.userinfo.orgCode,
      staffId: parent.vue.loginInfo.userinfo.id,
      check: "true",
    }).then((res) => {
      if (res.code == 200) {
        this.cityList = res.dataList;
        if(res.dataList.length == '1' && res.dataList[0].selected == 'selected') {
          this.formValidate.cityCode = res.dataList[0].code;
          this.changeCity(this.formValidate.cityCode);
        }
      }
    });
     //获取信息类型
    this.$get('/voteapi/api/pc/menu/getMenuParam',{
        columnCode: this.$core.getUrlParam('menuId') || 200,
         orgCode: parent.vue.loginInfo.userinfo.orgCode,
        // columnCode: '1701'
      }).then(res => {
        this.subtypeList = res; 
        if(this.subtypeList && this.subtypeList.length){
          this.formValidate.subtype = this.subtypeList[0].value
        }
      })
  },

  methods: {
    // 发布终端全选
    allSelect(arr) {
      if (this.terminalList.length == 4 && arr.indexOf("0") == -1) {
        this.formValidate.terminal = [];
      } else if (this.terminalList.length == 4 && arr.indexOf("0") > -1) {
        arr.splice(arr.indexOf("0"), 1);
        this.formValidate.terminal = arr;
      } else if (arr.length == 3 && arr.indexOf("0") == -1) {
        this.formValidate.terminal = ["0", "1", "3", "4"];
      } else if (arr.indexOf("0") > -1 && arr.length < 4) {
        this.formValidate.terminal = ["0", "1", "3", "4"];
      }
      this.terminalList = JSON.parse(JSON.stringify(this.formValidate.terminal));
    },
     loadUeditor() {
      if(this.formValidate&&this.formValidate.content != '' && this.formValidate.content != null){
        var v_Obj=document.getElementById("ueditor").contentWindow;//获取iframe对象
       v_Obj.setContent(this.formValidate.content);//写入编辑器富文本内容
      }
    },
    //取消裁剪
    cropperCancel(status) {
      this.cropperStatus = status;
    },

    //图片裁剪
    cropperFn() {
      this.option.img = this.formValidate.photo;
      this.cropperStatus = true;
    },
       //确定裁剪
    cropperFinish() {
      this.$refs.cropper.getCropBlob((data) => {
        this.$refs.imgUpload.clearFiles();
          let formData=new FormData();
          formData.append('name',this.uploadImageData.name);
          formData.append('host',this.uploadImageData.host);
          formData.append('key',this.uploadImageData.key);
          formData.append('policy',this.uploadImageData.policy);
          formData.append('OSSAccessKeyId',this.uploadImageData.OSSAccessKeyId);
          formData.append('success_action_status',this.uploadImageData.success_action_status);
          formData.append('signature',this.uploadImageData.signature);
          formData.append('file',data);
          this.$post('https://liefeng.oss-cn-shenzhen.aliyuncs.com',formData,{
            'Content-Type':'multipart/form-data'
          }).then(res => {
            this.$Message.success({
              background: true,
              content: "裁剪完成"
            });
            let imagePath = this.formValidate.photo;
            this.formValidate.photo = '';
            this.$nextTick(() => {
              this.formValidate.photo = imagePath;
              this.cropperCancel(false);
            })
          })
      })
    },
    // 图片上传进度
    imageProgress(event,file,fileList) {
      this.file = file;
       event.target.onprogress = (event) => {
      let uploadPercent = parseFloat(((event.loaded / event.total) * 100).toFixed(2))	// 保留两位小数，具体根据自己需求做更改
         // 手动设置显示上传进度条 以及上传百分比
      file.showProgress = true
      file.percentage = uploadPercent
       }
    },
    // 图片格式错误
    imageError(file) {
       this.$Notice.warning({
          title: '上传文件格式错误！',
          desc: '文件 ' + file.name + ' 上传失败, 请上传jpg, jpeg, png格式的图片！'
      });
    },
    //图片上传成功
    imageSuccess(file) {
      if (file.status == "finished") {
        this.formValidate.photo  = this.imagePath;
         this.$Message["success"]({
          background: true,
          content: "图片上传成功！",
        });
      } else {
        this.$Message["error"]({
          background: true,
          content: "图片上传失败！",
        });
      }
    },
    //上传图片之前
    beforeImage(file) {
       return this.$get(
        "/datamsg/api/common/file/getWebPolicy?bucketName=liefeng"
      ).then((res) => {
        if (res.code == 200) {
          let now = Date.parse(new Date()) / 1000;
          //防止文件名有.
          let suffixArr = file.name.split('.');

          let suffix = '.' + suffixArr[suffixArr.length -1];
          this.actionImage = "https://liefeng.oss-cn-shenzhen.aliyuncs.com";
          this.uploadImageData = {
            name: now + suffix,
            host: res.data.host,
            key: res.data.key + now + suffix,
            policy: res.data.encodedPolicy,
            OSSAccessKeyId: res.data.accessId,
            success_action_status: "200",
            signature: res.data.postSignature,
          };
          this.imageName = now + suffix;
          this.imagePath = res.data.host + res.data.key + this.imageName;
        }
      });
    },
    changeCity(value) {
       this.cityCode = value;
      this.$get("/voteapi/api/pc/cascader/getGovAreaByParentCode", {
        parentCode: this.cityCode,
        staffId: parent.vue.loginInfo.userinfo.id,
        orgCode: parent.vue.loginInfo.userinfo.orgCode,
        check: "true",
      }).then((res) => {
        if (res.code == 200) {
          this.areaList = res.dataList;
           if(res.dataList.length == '1' && res.dataList[0].selected == 'selected') {
            this.formValidate.areaCode = res.dataList[0].code;
            this.changeArea(this.formValidate.areaCode);
          }
        }
      });
    },
    changeArea(value) {
      this.areaCode = value;
      this.$get("/voteapi/api/pc/cascader/getGovAreaByParentCode", {
        parentCode: this.areaCode,
        staffId: parent.vue.loginInfo.userinfo.id,
        orgCode: parent.vue.loginInfo.userinfo.orgCode,
        check: "true",
      }).then((res) => {
        if (res.code == 200) {
          this.streeList = res.dataList;
          if(res.dataList.length == '1' && res.dataList[0].selected == 'selected') {
            this.formValidate.streetCode = res.dataList[0].code;
            this.changeStree(this.formValidate.streetCode);
          }
        }
      });
    },
    changeStree(value) {
      this.streetCode = value;
      this.$get("/voteapi/api/pc/cascader/getProjectList", {
        parentCode: this.parentCode,
        cityCode: this.cityCode,
        areaCode: this.areaCode,
        streetCode: this.streetCode,
        staffId: parent.vue.loginInfo.userinfo.id,
        orgCode: parent.vue.loginInfo.userinfo.orgCode,
        privilege: "true",
      }).then((res) => {
        if (res.code == 200) {
          this.projectList = res.dataList;
          if(res.dataList.length == '1' && res.dataList[0].selected == 'selected') {
            this.formValidate.projectCodeList = res.dataList[0].code;
          }
        }
      });
    },
  },
  components: {
    VueCropper,LiefengModal,Mymodel
  },
};
</script>

<style scoped lang='less'>
// 截图
.cropper-content {
    .cropper {
        width: 580px;
        height: 428px;
    }
}
.baseInto {
  height: 100%;
}
.ivu-form {
  .form-item {
    width: 50%;
    display: inline-block;
  }
  /deep/.ivu-select-dropdown {
    z-index: 99999;
  }
  .ivu-date-picker {
    width: 100%;
  }
  .time-interval {
    width: 1%;
    margin: 0 1%;
  }
}
</style>
